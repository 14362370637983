import * as React from "react"
import styled, { keyframes } from 'styled-components'
import Title from "../../common/Title";
import Subtitle from "../../common/Subtitle";
import Link from "../../common/Link"
import TractorAndField from '../../images/tractor-and-field.svg'
import ChainlinkLogo from '../../images/assets/chainlink-logo.svg'
import ETHLogo from '../../images/assets/ETH-logo.svg'
import USDCLogo from '../../images/assets/USDC-logo.svg'
import BTCLogo from '../../images/assets/bitcoin-logo.svg'
import MoreAssetsGraphic from '../../images/more-assets-graphic.svg'

const LeveragedFarmingSectionWrapper = styled.div`
  width: 100%;
  color: #f9f9f9;
  padding-bottom: 100px;
  padding-top: 100px;
  background: #f4f4ff;
  color: black;
  
  > div {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 50px;
  }
  
  @media screen and (max-width: 880px) {
    > div {
      max-width: 480px;
      padding: 0;
    }
  }
`

const Content = styled.div`
  width: calc(70% - 75px);
  padding: 25px;
  padding-left: 50px;
  margin: 0 auto;
  display: inline-block;
  vertical-align: top;
  margin-right: -10%;
  
  @media screen and (max-width: 1300px) {
    margin-right: 0;
    width: calc(60% - 75px);
  }
  
  @media screen and (max-width: 880px) {
    width: calc(100% - 50px);
    padding: 25px 25px;
  }
`

const Images = styled.div`
  width: 30%;
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 180px;
  margin-right: -5%;
  
  img {
    width: 80%;
  }
  
  @media screen and (max-width: 1100px) {
    img {
      margin-top: calc(300px - 90%);
    }
  }
  
  @media screen and (max-width: 880px) {
    height: fit-content;
    width: 60%;
    margin-left: 20%;
    
    img {
      width: 100%;
    }
  }
`

// markup
const LeveragedFarmingSection = () => {

  return (
    <LeveragedFarmingSectionWrapper>
      <div>
        <Images>
          <img src={MoreAssetsGraphic} />
        </Images>
        <Content>
          <Title>
            Don’t sacrifice your DeFi-native Rights
          </Title>
          <Subtitle>
            Dolomite believes in the fundamental rights of DeFi users. When you lend assets on Dolomite, you don’t forfeit the utility of those assets: governance rights, yield bearing, staking rewards, etc. Lend on Dolomite and retain your DeFi-native Rights — always.
          </Subtitle>
          <Link text={'Learn how our virtual liquidity model makes it work'} dest={'https://docs.dolomite.io/#what-is-capital-efficiency'} breakWidth={410} />
        </Content>
      </div>
    </LeveragedFarmingSectionWrapper>
  )
}

export default LeveragedFarmingSection
